import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "event" }
const _hoisted_2 = { class: "center-text title" }
const _hoisted_3 = { class: "center-text openingTimes" }
const _hoisted_4 = {
  key: 0,
  class: "artists left-align"
}
const _hoisted_5 = {
  key: 1,
  class: "curators left-align"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageGallery = _resolveComponent("ImageGallery")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Pattern = _resolveComponent("Pattern")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.event.title), 1),
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.openingTimes), 1),
      (_ctx.event.artists)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_4, "With works by " + _toDisplayString(_ctx.event.artists), 1))
        : _createCommentVNode("", true),
      (_ctx.event.curator)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_5, "Curated by " + _toDisplayString(_ctx.event.curator), 1))
        : _createCommentVNode("", true),
      (_ctx.event.images)
        ? (_openBlock(), _createBlock(_component_ImageGallery, {
            key: 2,
            class: "imageGallery",
            images: _ctx.event.images
          }, null, 8, ["images"]))
        : _createCommentVNode("", true),
      _createElementVNode("p", {
        class: "description",
        innerHTML: _ctx.formatMarkdown(_ctx.event.description)
      }, null, 8, _hoisted_6),
      _createVNode(_component_Footer)
    ]),
    (_ctx.rendered)
      ? (_openBlock(), _createBlock(_component_Pattern, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}