import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["v-show", "src", "alt"]
const _hoisted_4 = ["v-show"]
const _hoisted_5 = { class: "caption" }
const _hoisted_6 = { class: "position" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLoad && _ctx.handleLoad(...args))),
        "v-show": _ctx.loaded,
        src: _ctx.url,
        alt: _ctx.image.alternativeText
      }, null, 40, _hoisted_3),
      _createElementVNode("div", {
        "v-show": _ctx.loaded,
        class: "info"
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.image.caption), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", null, _toDisplayString(_ctx.position), 1)
        ])
      ], 8, _hoisted_4)
    ]),
    _createElementVNode("span", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.previousImage && _ctx.previousImage(...args))),
      class: "click-area-left"
    }),
    _createElementVNode("span", {
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextImage && _ctx.nextImage(...args))),
      class: "click-area-right"
    })
  ]))
}