import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageContainer = _resolveComponent("ImageContainer")!

  return (_openBlock(), _createBlock(_component_ImageContainer, {
    class: "image",
    image: _ctx.image,
    position: _ctx.position,
    onPreviousImage: _ctx.decrementIndex,
    onNextImage: _ctx.incrementIndex
  }, null, 8, ["image", "position", "onPreviousImage", "onNextImage"]))
}